<template>
  <!-- 消息页面 -->
  <div class="message_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>消息通知</span>
      </div>
    </div>

    <div class="message_list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="message_list_box">
            <div
              class="message_item"
              v-for="(item, index) in msgList"
              :key="index"
              @click="onClickMsg(item)"
            >
              <div class="item_top">
                <div class="item_type">
                  <div class="type">{{ item.title }}</div>
                  <div class="read" :class="{ isRead: item.read }">
                    {{ item.read ? "已读" : "未读" }}
                  </div>
                </div>
                <div class="item_time">{{ item.dateTimeStr }}</div>
              </div>
              <div class="item_bottom">
                <div class="item_bottom_left">
                  <div class="item_location">
                    <img src="../assets/images/停车场.png" alt="" />
                    <span>{{ item.parkingName }}</span>
                  </div>
                  <div class="item_parkingNumber">
                    车位号：{{ item.parkingNumber }}
                  </div>
                </div>
                <div
                  class="item_bottom_right"
                  :class="{
                    free: item.type == 20,
                    whiteList: item.type == 21,
                    arrear: item.type == 40
                  }"
                >
                  {{ item.plateNumber }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    msgList: [], //消息列表
    loading: false,
    finished: false,
    refreshing: false,

    pageSize: 10, //每页数据量
    pageNum: 1, //页码
    total: 0 //总条数
  }),
  methods: {
    /**
     * 列表加载
     */
    onLoad() {
      // this.$get(this.$config.TL_MESSAGE_GET_MESSAGE_LIST, {
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize
      // })
      this.$api
        .getMessageList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        .then(res => {
          console.log("res ==> ", res);
          if (this.refreshing) {
            this.msgList = [];
            this.refreshing = false;
          }

          this.loading = false;

          this.total = res["data"]["data"]["totalElements"];
          if (
            res["data"]["data"]["content"] &&
            res["data"]["data"]["content"].length > 0
          ) {
            res["data"]["data"]["content"].forEach(item => {
              this.msgList.push(item);
            });
          }

          this.pageNum++;

          if (this.msgList.length >= this.total) {
            this.finished = true;
          }
        });
    },

    /**
     * 刷新列表
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      this.pageNum = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    /**
     * 点击单条消息
     */
    onClickMsg(item) {
      // this.$put(`${this.$config.TL_MESSAGE_UPDATE_CAPTION_MESSAGE}${item.id}`, {
      //   captainMessageId: item.id
      // })
      this.$api.updateCaptainMessage(item.id).then(() => {
        this.$router.push({
          name: "PlateNumber",
          query: {
            pageState: item.type,
            plateNumber: item.plateNumber
          }
        });
      });
    }
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp
    })
  },

  mounted() {
    this.msgList = [];
    this.onRefresh();
  }
};
</script>

<style lang="less" scoped>
.message_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message_list {
  flex: 1;
  width: 104%;
  padding: 0 2%;
  transform: translateX(-2%);
  background-color: #edf0f7;
  overflow: scroll;

  .message_list_box {
    width: 100%;
    padding: 14px;
  }

  .message_item {
    width: 100%;
    padding: 10px 14px;
    background-color: #fff;
    margin-bottom: 14px;
    border-radius: 6px;
    box-shadow: 2px 2px 4px #ccc;

    .item_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .item_type {
        font-size: 18px;
        color: #333;
        font-weight: 600;
        display: flex;
        align-items: center;

        .type {
          margin-right: 8px;
        }

        .read {
          font-size: 12px;
          padding: 2px 8px;
          background-color: red;
          color: #fff;
          border-radius: 4px;

          &.isRead {
            background-color: #ccc;
          }
        }
      }

      .item_time {
        font-size: 14px;
        color: #8b929a;
      }
    }

    .item_bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #333;
      // padding-left: 6px;

      .item_bottom_left {
        font-size: 14px;

        .item_location {
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          > span {
            font-size: 16px;
          }

          > img {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }
      }

      .item_bottom_right {
        width: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }
    }
  }
}

.arrear {
  color: #fa5e5e !important;
}

.free {
  color: #40c09d !important;
}
.whiteList {
  color: #288ee7 !important;
}
</style>
